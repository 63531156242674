<template>
  <div>
    <DeviceList />
  </div>
</template>

<script>

import DeviceList from '@/components/device/Device';

export default {
  name: 'Devices',

  components: {
    DeviceList,
  },
};

</script>
